const Header = {
  init: () => {
    // get mobile nav btn
    const btn = document.getElementById("header__btn");

    // get nav
    const nav = document.getElementById("header__nav");

    if (nav) {
      const links = Array.from(nav.querySelectorAll("a"));

      if (links) {
        links.forEach((item) => {
          item.addEventListener("click", function () {
            if (btn) Header.closeNav(btn);
          });
        });
      }
    }

    // mobile nav toggle
    if (btn) {
      btn.addEventListener("click", function (e) {
        Header.handleNavToggle(this);
      });
    }
  },

  handleNavToggle: (btn) => {
    // get aria labels
    const labelOpen = btn.dataset.ariaLabelOpen;
    const labelClose = btn.dataset.ariaLabelClose;

    // toggle aria expanded attr
    let isExpanded = btn.getAttribute("aria-expanded") === "true" || false;
    btn.setAttribute("aria-expanded", !isExpanded);

    // toggle aria labels
    if (!isExpanded) {
      btn.setAttribute("aria-label", labelClose);
    } else {
      btn.setAttribute("aria-label", labelOpen);
    }
  },

  closeNav: (btn) => {
    // get aria label
    const labelOpen = btn.dataset.ariaLabelOpen;

    // force close nav
    btn.setAttribute("aria-expanded", false);
    btn.setAttribute("aria-label", labelOpen);
  },
};

export default Header;
