import Form from "./Form";
import Header from "./Header";

const app = {
  init: () => {
    console.log("Welcome to SwedAPL.");

    Form.init();
    Header.init();
  },
};

document.readyState === "loading"
  ? document.addEventListener("DOMContentLoaded", app.init)
  : app.init();
