const Form = {
  init: () => {
    const contactForm = document.getElementById("form-contact");

    if (contactForm) {
      contactForm.addEventListener("submit", (e) => {
        e.preventDefault();

        // update btn status
        Form.setBtnState(e.target, "send");

        // handle submit
        Form.handleSubmit(e);
      });
    }
  },

  setBtnState: (form, status) => {
    if (form) {
      const btn = form.querySelector(".btn");
      const btnTxt = btn ? btn.querySelector(".btn__text") : null;

      if (btn) {
        if (status == "send") {
          btn.disabled = true;
          btn.classList.add("animate--pulsate");
          if (btnTxt) btnTxt.innerText = "Sending...";
        } else {
          btn.disabled = false;
          btn.classList.remove("animate--pulsate");

          if (status == "ok") {
            if (btnTxt) btnTxt.innerText = "Thank you!";
          } else {
            if (btnTxt) btnTxt.innerText = "Try again later.";
          }
        }
      }
    }
  },

  encodeData: (data) => {
    const payload = [];

    for (const [key, value] of Object.entries(data)) {
      payload.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
    }

    //console.log(payload);
    //console.log(payload.join("&"));

    return payload.join("&");
  },

  handleSubmit: (e) => {
    // submitted form
    const currentForm = e.target;

    // ajax url
    const url = "index.php";

    // create new FormData from form
    const formData = new FormData(e.target);

    // create obj from FormData entries
    const formEntries = Object.fromEntries(formData);

    // prepare data
    const data = {
      action: "bswp_contact_form_submit",
      nonce: siteData.nonce,
      ...formEntries,
    };

    ////console.log(data);

    // encode data before submitting
    const payload = Form.encodeData(data);

    // setup fetch options
    const options = {
      method: "POST",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: payload,
    };

    fetch(url, options)
      .then((res) => {
        //console.log(res);
        // promise returned
        if (!res.ok) throw new Error(`HTTP error: ${res.status}`);
        return res.json();
      })
      .then((data) => {
        // success
        //console.log(data);

        // clear form to prevent spamming
        currentForm.reset();

        // update btn status
        Form.setBtnState(currentForm, "ok");
      })
      .catch((err) => {
        // error
        //console.log(err);

        // update btn status
        Form.setBtnState(currentForm, "error");
      });
  },
};

export default Form;
